@use "constants.scss";

* {
  box-sizing: border-box;
  outline: none;
  position: relative;
  user-select: none;
  -moz-user-select: none;
  -webkit-user-select: none;
}
a {
  text-decoration: none;
}
html,
body {
  margin: 0;
  padding: 0;
  height: 100%;
  min-height: 100vh;
  width: 100%;
  max-width: 100%;
  background-color: #000000;
  color: #fafafa;
  font-family: "Poppins", monospace, Roboto, -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", monospace, sans-serif;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

#header {
  display: flex;
  flex-direction: column;
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  background-color: #000610;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  z-index: 9999;
  box-shadow: 4px 0p 4px 0px #000000;
  .hideOnMobile {
    @media only screen and (max-width: 1000px) {
      display: none;
    }
  }
  .hideOnDesktop {
    @media only screen and (min-width: 1000px) {
      display: none;
    }
  }
}

#footer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  padding-inline: var(--page-padding);
  width: 100%;
  overflow-x: hidden;
  gap: calc(var(--size) * 2);
  @media only screen and (max-width: 700px) {
    background-image: url("./assets/images/footer-bg.svg");
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
  }
}

.container {
  height: 100%;
  width: 100%;
  padding-block: 120px;
}

.blue-text {
  color: #0156fc;
}

img {
  user-select: none;
  -moz-user-select: none;
  -webkit-user-select: none;
}

:root {
  --size: 12px;
  --page-padding: calc(var(--size) * 0.5);
  @media only screen and (min-width: 500px) and (max-width: 1000px) {
    --size: 14px;
    --page-padding: calc(var(--size) * 3);
  }
  @media only screen and (min-width: 1000px) and (max-width: 1500px) {
    --size: 16px;
    --page-padding: calc(var(--size) * 3);
  }
  @media only screen and (min-width: 1500px) {
    --size: 20px;
    --page-padding: calc(var(--size) * 5);
  }
}
